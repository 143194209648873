<template>
  <div>
    <v-card class="overflow-hidden" color="blue lighten-5" :loading="loading">
      <v-toolbar class="primary" tag="div" flat dark tile>
        <div class="text-h6">{{ $t("globalSearch") }}</div></v-toolbar
      >

      <v-row no-gutters class="mt-3">
        <v-col cols="12" sm="4" class="px-1">
          <v-text-field
            v-model="query"
            clearable
            :loading="loading"
            prepend-inner-icon="mdi-magnify"
            outlined
            dense
            autofocus
            placeholder="Write Here..."
          ></v-text-field
        ></v-col>
        <v-spacer></v-spacer>

        <v-btn
          class="mx-1"
          :loading="loading"
          :disabled="
            loading ||
            query == null ||
            query == '' ||
            query.length < 5 ||
            (policiescount == 0 && leadscount == 0 && customerscount == 0)
          "
          text
          @click="getSearch"
        >
          <v-icon>mdi-autorenew</v-icon>
          {{ $t("labelUpdate") }}
        </v-btn>
      </v-row>
      <v-divider class="mb-2"></v-divider>
      <template>
        <v-sheet rounded class="mx-auto pa-1" color="primary lighten-4">
          <div class="d-flex justify-center">
            <h4 class="">{{ `Total Policies (${policiescount})` }}</h4>
          </div>
          <v-data-table
            :headers="headersP"
            :loading="loading"
            dense
            :items="policiesdata"
            :items-per-page="5"
            @click:row="showDetailsP"
            class="elevation-0 cursor-pointer"
          >
            <template v-slot:[`item.fullname`]="{ item }">
              <span class="text-uppercase">{{ item.fullname }}</span>
            </template>
            <template v-slot:[`item.agent`]="{ item }">
              <span class="text-uppercase">{{ getNameAgent(item) }}</span>
            </template>
            <template v-slot:[`item.policyYear`]="{ item }">
              <template v-if="item.effectiveDate">
                {{
                  item.effectiveDate ? formatDateEY(item.effectiveDate) : "-"
                }}
              </template>
              <template v-else> - </template></template
            >
            <template v-slot:[`item.polizeStatus`]="{ item }">
              <div v-bind:style="{ color: colorShow(item) }">
                {{ statusShow(item.polizeStatus) }}
              </div>
            </template>
          </v-data-table>
        </v-sheet>

        <v-divider class="my-2"></v-divider>
      </template>

      <template>
        <v-sheet rounded class="mx-auto pa-1" color="primary lighten-3">
          <div class="d-flex justify-center">
            <h4 class="">{{ `Total Leads (${leadscount})` }}</h4>
          </div>
          <v-data-table
            :headers="headersL"
            :loading="loading"
            dense
            :items="leadsdata"
            :items-per-page="5"
            @click:row="showDetailsL"
            class="elevation-0 cursor-pointer"
          >
            <template v-slot:[`item.fullname`]="{ item }">
              <span class="text-uppercase"
                >{{ item.name
                }}{{ item.lastName ? " " + item.lastName : "" }}</span
              >
            </template>
            <template v-slot:[`item.agent`]="{ item }">
              <span class="text-uppercase">{{ getNameAgent(item) }}</span>
            </template>

            <template v-slot:[`item.phone`]="{ item }">
              {{ "..... " + item.phone.slice(item.phone.length - 4) }}
            </template>
          </v-data-table>
        </v-sheet>
        <v-divider class="my-2"></v-divider>
      </template>
      <template>
        <v-sheet rounded class="mx-auto pa-1" color="primary lighten-2">
          <div class="d-flex justify-center">
            <h4 class="">{{ `Total Customers (${customerscount})` }}</h4>
          </div>
          <v-data-table
            :headers="headersC"
            :loading="loading"
            dense
            :items="customersdata"
            :items-per-page="5"
            @click:row="showDetailsC"
            class="elevation-0 cursor-pointer"
          >
            <template v-slot:[`item.fullname`]="{ item }">
              <span class="text-uppercase"
                >{{ item.name
                }}{{ item.lastName ? " " + item.lastName : "" }}</span
              >
            </template>
            <template v-slot:[`item.phone`]="{ item }">
              {{ "..... " + item.phone.slice(item.phone.length - 4) }}
            </template>
            <template v-slot:[`item.isActive`]="{ item }">
              <template v-if="item.isActive"
                ><v-icon color="success">mdi-check</v-icon></template
              >
              <template v-else>
                <v-icon color="error">mdi-close</v-icon></template
              >
            </template>
          </v-data-table></v-sheet
        >
        <v-divider class="my-2"></v-divider>
      </template>
    </v-card>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import moment from "moment";
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      query: null,
      loading: false,
      policiesdata: [],
      policiescount: 0,
      leadsdata: [],
      leadscount: 0,
      customersdata: [],
      customerscount: 0,

      headersP: [
        { text: "Fullname", width: 300, value: "fullname", sortable: false },
        { text: "Agent", width: 300, value: "agent", sortable: false },
        {
          text: "Policy Year",
          width: 150,
          show: true,
          value: "policyYear",
          sortable: true,
        },
        {
          text: "Policy Type",
          width: 150,
          value: "policyType",
          sortable: false,
        },

        { text: "Policy Status", value: "polizeStatus", sortable: false },
        { text: "Members", value: "totalMembers", sortable: false },
      ],
      headersL: [
        { text: "Fullname", width: 300, value: "fullname", sortable: false },
        { text: "Agent", width: 300, value: "agent", sortable: false },
        { text: "Phone", width: 150, value: "phone", sortable: false },
        { text: "Email", value: "email", sortable: false },
      ],
      headersC: [
        { text: "Fullname", width: 300, value: "fullname", sortable: false },
        { text: "Phone", width: 150, value: "phone", sortable: false },
        { text: "Email", value: "email", sortable: false },
        { text: "Active", value: "isActive", sortable: false },
      ],
    };
  },
computed:{
  ...mapState(['globalSearch'])
},
  watch: {
    query: {
      handler(val) {
        if (val == null || val == "") {
           this.updateGlobalSearch("");
          this.policiesdata = [];
          this.policiescount = 0;
          this.leadsdata = [];
          this.leadscount = 0;
          this.customersdata = [];
          this.customerscount = 0;
        }
        if (val && val.length >2) {
          this.getSearch();
        }
      },
    },
  },
  methods: {
  ...mapMutations(['updateGlobalSearch']),
    getSearch() {
      const body = {
        limit: 250,
        offset: 0,
        query: this.query,
      };
      this.loading = true;
      this.updateGlobalSearch(this.query);     
      getAPI
        .post("/customer/globalSearch", body)
        .then((res) => {
          this.loading = false;
          this.gruopData(res.data);
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    gruopData(data) {
      if (data) {
        this.policiesdata = data.policies.result;
        this.policiescount = data.policies.count;
        this.leadsdata = data.leads.result;
        this.leadscount = data.leads.count;
        this.customersdata = data.customers.result;
        this.customerscount = data.customers.count;
      } else {
        this.policiesdata = [];
        this.policiescount = 0;
        this.leadsdata = [];
        this.leadscount = 0;
        this.customersdata = [];
        this.customerscount = 0;
      }
    },

    statusShow(item) {
      console.log(item);
      if (item) {
        const x = item;
        return x.replaceAll("_", " ");
      }
      return "-";
    },

    colorShow(item) {
      const status = item.polizeStatus;
      if (status != null && status != undefined) {
        switch (status) {
          case "ACTIVE":
            return "#19FF00FF";
          case "ACTIVE_PENDING":
            return "#4B7ECE";
          case "CANCELLED":
            return "#FF4400";
          case "PAY_DUE":
            return "#4B7ECE";
          case "REVIEW":
            return "#EEC167";
          case "BLACKLIST":
            return "#270E41";
          case "END":
            return "#19FF00FF";
          default:
            return "#000";
        }
      }
      return "#000";
    },

    showDetailsP(policy) {
      this.$router.push(`/healths/details/${policy.uuid}`);
    },
    showDetailsL(lead) {
      this.$router.push(`/leads_details/${lead.uuid}`);
    },
    showDetailsC(customer) {
      this.$router.push(`/customers/details/${customer.uuid}`);
    },
    formatDateEY(date) {
      const datesplit = date.split("T")[0];
      const d = moment(datesplit).format("YYYY");
      return d;
    },
    getNameAgent(item) {
      if (item.relations && item.relations.length != 0) {
        return item.relations[0].employee.fullname;
      }
      return "-";
    },
  },

  mounted() {
    this.query = this.globalSearch;
  
  },
};
</script>
