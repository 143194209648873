var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"overflow-hidden",attrs:{"color":"blue lighten-5","loading":_vm.loading}},[_c('v-toolbar',{staticClass:"primary",attrs:{"tag":"div","flat":"","dark":"","tile":""}},[_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.$t("globalSearch")))])]),_c('v-row',{staticClass:"mt-3",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"clearable":"","loading":_vm.loading,"prepend-inner-icon":"mdi-magnify","outlined":"","dense":"","autofocus":"","placeholder":"Write Here..."},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),_c('v-spacer'),_c('v-btn',{staticClass:"mx-1",attrs:{"loading":_vm.loading,"disabled":_vm.loading ||
          _vm.query == null ||
          _vm.query == '' ||
          _vm.query.length < 5 ||
          (_vm.policiescount == 0 && _vm.leadscount == 0 && _vm.customerscount == 0),"text":""},on:{"click":_vm.getSearch}},[_c('v-icon',[_vm._v("mdi-autorenew")]),_vm._v(" "+_vm._s(_vm.$t("labelUpdate"))+" ")],1)],1),_c('v-divider',{staticClass:"mb-2"}),[_c('v-sheet',{staticClass:"mx-auto pa-1",attrs:{"rounded":"","color":"primary lighten-4"}},[_c('div',{staticClass:"d-flex justify-center"},[_c('h4',{},[_vm._v(_vm._s(("Total Policies (" + _vm.policiescount + ")")))])]),_c('v-data-table',{staticClass:"elevation-0 cursor-pointer",attrs:{"headers":_vm.headersP,"loading":_vm.loading,"dense":"","items":_vm.policiesdata,"items-per-page":5},on:{"click:row":_vm.showDetailsP},scopedSlots:_vm._u([{key:"item.fullname",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(item.fullname))])]}},{key:"item.agent",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.getNameAgent(item)))])]}},{key:"item.policyYear",fn:function(ref){
          var item = ref.item;
return [(item.effectiveDate)?[_vm._v(" "+_vm._s(item.effectiveDate ? _vm.formatDateEY(item.effectiveDate) : "-")+" ")]:[_vm._v(" - ")]]}},{key:"item.polizeStatus",fn:function(ref){
          var item = ref.item;
return [_c('div',{style:({ color: _vm.colorShow(item) })},[_vm._v(" "+_vm._s(_vm.statusShow(item.polizeStatus))+" ")])]}}],null,true)})],1),_c('v-divider',{staticClass:"my-2"})],[_c('v-sheet',{staticClass:"mx-auto pa-1",attrs:{"rounded":"","color":"primary lighten-3"}},[_c('div',{staticClass:"d-flex justify-center"},[_c('h4',{},[_vm._v(_vm._s(("Total Leads (" + _vm.leadscount + ")")))])]),_c('v-data-table',{staticClass:"elevation-0 cursor-pointer",attrs:{"headers":_vm.headersL,"loading":_vm.loading,"dense":"","items":_vm.leadsdata,"items-per-page":5},on:{"click:row":_vm.showDetailsL},scopedSlots:_vm._u([{key:"item.fullname",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(item.name)+_vm._s(item.lastName ? " " + item.lastName : ""))])]}},{key:"item.agent",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.getNameAgent(item)))])]}},{key:"item.phone",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s("..... " + item.phone.slice(item.phone.length - 4))+" ")]}}],null,true)})],1),_c('v-divider',{staticClass:"my-2"})],[_c('v-sheet',{staticClass:"mx-auto pa-1",attrs:{"rounded":"","color":"primary lighten-2"}},[_c('div',{staticClass:"d-flex justify-center"},[_c('h4',{},[_vm._v(_vm._s(("Total Customers (" + _vm.customerscount + ")")))])]),_c('v-data-table',{staticClass:"elevation-0 cursor-pointer",attrs:{"headers":_vm.headersC,"loading":_vm.loading,"dense":"","items":_vm.customersdata,"items-per-page":5},on:{"click:row":_vm.showDetailsC},scopedSlots:_vm._u([{key:"item.fullname",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(item.name)+_vm._s(item.lastName ? " " + item.lastName : ""))])]}},{key:"item.phone",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s("..... " + item.phone.slice(item.phone.length - 4))+" ")]}},{key:"item.isActive",fn:function(ref){
          var item = ref.item;
return [(item.isActive)?[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")])]:[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])]]}}],null,true)})],1),_c('v-divider',{staticClass:"my-2"})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }